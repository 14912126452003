import React from 'react';

interface FilterModalProps {
    seriesNames: string[];
    selectedSeries: Set<string>;
    onChange: (selected: Set<string>) => void;
    onClose: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({ seriesNames, selectedSeries, onChange, onClose }) => {
    const handleCheckBoxChange = (seriesName: string, isChecked: boolean) => {
        const newSelectedSeries = new Set(selectedSeries);
        if (isChecked) {
            newSelectedSeries.add(seriesName);
        } else {
            newSelectedSeries.delete(seriesName);
        }
        onChange(newSelectedSeries);
    };

    return (
        <div className="fixed inset-0 z-50 overflow-auto flex">
            <div className="modal-content border relative p-4 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
                <h2 className="text-slate-700 text-lg font-medium mb-4">Add/remove provinces or regions</h2>
                <div className="overflow-y-auto">
                    {seriesNames.map(name => (
                        <div key={name} className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                checked={selectedSeries.has(name)}
                                onChange={(e) => handleCheckBoxChange(name, e.target.checked)}
                                className="form-checkbox h-4 w-4"
                            />
                            <label className="ml-2 text-xs text-slate-700">{name}</label>
                        </div>
                    ))}
                </div>
                <button 
                    onClick={onClose} 
                    className="mt-4 text-slate-700 p-2 text-xs rounded-md hover:bg-slate-100 border-gray-200 border">
                    Close
                </button>
            </div>
        </div>
    );
};

export default FilterModal;