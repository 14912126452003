import './App.css';
import SiteHeader from './components/navigation/SiteHeader';
import LineChart from './components/lineCharts/LineChart';

interface DataPoint {
  date: Date;
  value: number;
}

interface Series {
  name: string;
  data: DataPoint[];
}

const seriesData: Series[] = [
  { name: 'Alberta', data: [
    { date: new Date(2017, 0), value: 30 },
    { date: new Date(2018, 0), value: 10 },
    { date: new Date(2019, 0), value: 50 },
    { date: new Date(2021, 0), value: 80 },
    { date: new Date(2022, 0), value: 70 }],
  },
  
  { name: 'Saskatchewan', data: [
    { date: new Date(2017, 0), value: 25 },
    { date: new Date(2018, 0), value: 15 },
    { date: new Date(2019, 0), value: 45 },
    { date: new Date(2020, 0), value: 5 },
    { date: new Date(2021, 0), value: 85 },
    { date: new Date(2022, 0), value: 65 }]
  }
];

function App() {
  return (
    <div className="h-screen flex flex-col">
      <SiteHeader title="Canadata" />
      <div className="mt-4 max-w-x1 mx-auto p-4 border">
        <LineChart
          seriesData={seriesData}
          width={800}
          height={600}
          title="GDP per Capita"
          subtitle="Consumption-based estimates converted into 2022 CAD."
          source="Statistics Canada. Gross domestic product, expenditure-based, provincial and territorial, annual. - "
          source_link="https://www150.statcan.gc.ca/t1/tbl1/en/tv.action?pid=3610022201"
          />
      </div>
    </div>
  );
};

export default App;