import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

import ChartHeader from '../headers/ChartHeader';
import ChartFooter from '../footer/ChartFooter';
import XAxis from '../axes/XAxis';
import YAxis from '../axes/YAxis';
import FilterModal from '../modals/FilterModal'

interface DataPoint {
    date: Date;
    value: number;
  }
  
  interface Series {
    name: string;
    data: DataPoint[];
  }

interface LineChartProps {
    seriesData: Series[];
    width: number;
    height: number;
    title: string;
    subtitle?: string;
    source: string;
    source_link: string;
    margin?: { top: number; right: number; bottom: number; left: number };
}

const LineChart: React.FC<LineChartProps> = ({
    seriesData,
    width,
    height,
    title,
    subtitle,
    source,
    source_link,
    margin = { top: 30, right: 0, bottom: 30, left: 0 } 
}) => {
    // Set state of the left margin. 0 by default
    const [leftMargin, setLeftMargin] = useState(0);
    margin.left = leftMargin;

    // Update the left margin based on width of the widest y-axis label
    const handleLeftMarginChange = (childWidth: number) => {
        const newLeftMargin = childWidth;
        setLeftMargin(newLeftMargin);
    }

    // Set state of the right margin. 0 by default.
    const [rightMargin, setRightMargin] = useState(0);
    
    // Handling the filtering
    // Set state for which series are selected for filtering
    const [selectedSeries, setSelectedSeries] = useState(new Set<string>());
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Initialize selectedSeries with all series names
    useEffect(() => {
        const seriesNames = seriesData.map (s => s.name);
        setSelectedSeries(new Set(seriesNames));
    }, [seriesData]);

    const handleFilterChange = (newSelectedSeries: Set<string>) => {
        setSelectedSeries(newSelectedSeries);
    };

    const filteredSeriesData = seriesData.filter(s => selectedSeries.has(s.name));

    // Set up the chart
    const ref = useRef<SVGSVGElement>(null);
    const color = d3.scaleOrdinal(d3.schemeCategory10);
    
    // Draw the chart
    const drawChart = () => {
        const svg = d3.select(ref.current)
            .select(".line-chart-area");
            
        // Clear SVG before redrawing
        svg.selectAll("*").remove();

        // Add an invisible vertical line for the hover-over
        svg.append("line")
            .attr("opacity", 0)
            .attr("y1", 0)
            .attr("y2", innerHeight)
            .attr("class", "stroke-0.5 stroke-slate-200 vertical-line")
            .attr("pointer-events", "none");

        // For measuring the width of the widest series label
        let widestSeriesLabel = 0;

        // Define the lines for each series
        filteredSeriesData.forEach((series, index) => {
            const line = d3.line<DataPoint>()
                .x(d => xScale(d.date))
                .y(d => yScale(d.value));
        
            // Draw and style the line
            const path = svg.append("path")
                .datum(series.data)
                .attr("fill", "none")
                .attr("stroke", color(index.toString())) // Color based on Index number
                .attr("stroke-width", 1.5)
                .attr("point", 5)
                .attr("d", line);

            svg.selectAll(`.dot-${index}`)
                .data(series.data)
                .enter().append("circle")
                .attr("class", `dot dot-${index}`)
                .attr("cx", d => xScale(d.date))
                .attr("cy", d => yScale(d.value))
                .attr("r", 2.5)
                .attr("fill", color(index.toString()));
                
            // Animate in the appearance of the line
            const totalLength = path.node()!.getTotalLength();
            path
                .attr("stroke-dasharray", totalLength + " " + totalLength)
                .attr("stroke-dashoffset", totalLength)
                .transition()
                .duration(800)
                .ease(d3.easeLinear)
                .attr("stroke-dashoffset", 0);

            // Get the last data point for each series
            const lastDataPoint = series.data[series.data.length - 1];

            // Append a label for each of these last data points
            const seriesLabel = svg.append("text")
                .attr("x", xScale(lastDataPoint.date) + 8) // 8 is arbitrary offset
                .attr("y", yScale(lastDataPoint.value))
                .style("fill", color(index.toString()))
                .attr("alignment-baseline", "middle")
                .attr("class", "font-poppins text-xs")
                .text(series.name);

            // Measure the width of the label and see if it's the new max width
            // Check the seriesLabel node is not null before attempting this
            const seriesLabelNode = seriesLabel.node();
            if (seriesLabelNode) {
                const seriesLabelWidth = seriesLabelNode.getBBox().width;
                if (seriesLabelWidth > widestSeriesLabel) widestSeriesLabel = seriesLabelWidth;
            } 
        });

        // Update right margin if necessary
        if (widestSeriesLabel + 20 > rightMargin) { // 20 is an arbitrary number
            setRightMargin(widestSeriesLabel + 20);
        }

        // Add an invisible rectangle for mouse tracking
        svg.append("rect")
            .attr("class", "overlay")
            .attr("width", innerWidth)
            .attr("height", innerHeight)
            .style("fill", "none")
            .style("pointer-events", "all")
            .on("mouseover", handleMouseOver)
            .on("mouseout", handleMouseOut)
            .on("mousemove", handleMouseMove);
    };

    const innerHeight = height - margin.top - margin.bottom;
    const innerWidth = width - margin.left - rightMargin;

    // Set up scales
    // Calculate the overall extent of the data
    const xExtent = d3.extent(filteredSeriesData.flatMap(series => series.data.map(d => d.date)));
    const yExtent = [0, d3.max(filteredSeriesData, series => d3.max(series.data, d => d.value))];

    // Create scales based on data extent
    const xScale = d3.scaleTime()
        .domain(xExtent as [Date, Date])
        .range([0, innerWidth]);

    const yScale = d3.scaleLinear()
        .domain(yExtent as [number, number])
        .range([innerHeight, 0]);

    // Add a function for drawing the bigger circles for hover-over
    const drawCircles = (nearestYear: any) => {
        const svg = d3.select(ref.current)
            .select(".line-chart-area");

            filteredSeriesData.forEach((series, index) => {
            const dataPoint = series.data.find(d => d.date.getFullYear() === nearestYear);

            if (dataPoint) {
                svg.append("circle")
                    .attr("class", "hover-circle")
                    .attr("cx", xScale(dataPoint.date))
                    .attr("cy", yScale(dataPoint.value))
                    .attr("r", 5) // Radius of the circle
                    .attr("fill", color(index.toString()))
                    .attr("stroke", "white")
                    .attr("stroke-width", 1.5)
            }
        });
    };

    // Draw the chart
    useEffect(() => {
        drawChart();
    }, [filteredSeriesData, xScale, yScale, rightMargin]);

    // Handle the mouseovers
    // Display the tooltip when hovering over the line chart area
    const handleMouseOver = () => {
        d3.select("#tooltip").style("opacity", 1);
    };

    // Hide the tooltip when mouse leaves the line chart area
    const handleMouseOut = () => {
        d3.select("#tooltip").style("opacity", 0);
        d3.select(".vertical-line")
            .attr("opacity", 0);
        d3.selectAll(".hover-circle").remove();
    };

    // Find the nearest year to the mouse position
    // Also, find the x-position of that year to position the vertical line
    const findNearestYear = (mouseX: number) => {
        let nearestYear = null;
        let nearestYearX = null;
        let minDistance = Infinity;

        filteredSeriesData.forEach(s => {
            s.data.forEach(d => {
                const currentX = xScale(d.date);
                const distance = Math.abs(mouseX - currentX);
                if (distance < minDistance) {
                    minDistance = distance;
                    nearestYear = d.date.getFullYear();
                    nearestYearX = currentX;
                }
            });
        });

        return { nearestYear, nearestYearX };
    };

    // Draw the tooltip based on the value nearest the mouse position
    const handleMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
        const svg = d3.select(ref.current);
        const mouse = d3.pointer(event);
        const mouseX = mouse[0];
        const { nearestYear, nearestYearX } = findNearestYear(mouseX);

        // Remove any existing circles
        d3.selectAll(".hover-circle").remove();

        // Add new circles
        drawCircles(nearestYear);

        // Find the values for each series at this year
        // If there is no data for the series at this year, return "No data" as value
        const valuesForYear = filteredSeriesData.map(s => {
            const dataPoint = s.data.find(d => d.date.getFullYear() === nearestYear);
            
            return {
                name: s.name,
                value: dataPoint ? dataPoint.value : "No data"
            };
        });
        
        // Style the tooltip
        const toolTipHTML = `
            <div class="bg-gray-200 rounded-t-md font-semibold p-2">${nearestYear}</div>
            <table class="m-2">
                <tbody>
                    ${valuesForYear.map((d, i) => {

                        // Gray out the text and rectangle if there is no data
                        const isNoData = d.value === "No data";
                        const textColorStyle = isNoData ? "text-slate-200" : `fill="${color(i.toString())}"`;
                        const fillColorStyle = isNoData ? `class="fill-slate-200"` : `fill="${color(i.toString())}"`;

                        return `
                            <tr class="${textColorStyle}">
                                <td class="text-left">
                                    <svg width="10" height="10" class="inline-block align-middle">
                                        <rect width="10" height="10" ${fillColorStyle}></rect>
                                    </svg>
                                    <span class="ml-1 mr-4">${d.name}</span>
                                </td>
                                <td class="font-semibold text-right">${d.value}</td>
                            </tr>
                        `;
                    }).join('')}
                </tbody>
            </table>
        `;

        // Position and populate the tooltip
        const tooltip = d3.select("#tooltip");
        tooltip.html(toolTipHTML)
            .style("left", (event.pageX + 10) + "px")
            .style("top", (event.pageY - 28) + "px");

        // Position and display the vertical line
        svg.select(".vertical-line")
            .attr("opacity", 1)
            .attr("transform", `translate(${nearestYearX}, 0)`);

    };
           
    return (
        <div>
            <ChartHeader title={title} subtitle={subtitle} />
            <div className="flex justify-end"> {/* Right-align the edit button */}
                <button 
                    onClick={() => setIsModalOpen(true)}
                    className="flex items-center hover:bg-slate-100 border-gray-200 border text-xs font-poppins text-slate-600 p-2 rounded-md">
                    Edit provinces and regions
                </button>
                {isModalOpen && (
                    <FilterModal   
                        seriesNames={seriesData.map(s => s.name)}
                        selectedSeries={selectedSeries}
                        onChange={handleFilterChange}
                        onClose={() => setIsModalOpen(false)}
                    />
                )}
            </div>
            <svg ref={ref} width={width} height={height} >
                <g transform={`translate(${margin.left},${margin.top})`}>
                    <XAxis xScale={xScale} translate={`translate(0, ${innerHeight})`} />
                    <YAxis yScale={yScale} innerWidth={innerWidth} onWidthChange={handleLeftMarginChange} />
                    <g className="line-chart-area"></g>
                </g>
            </svg>
            <ChartFooter source={source} source_link={source_link} />
            <div id="tooltip" className="absolute text-left rounded-md font-poppins text-xs bg-white border-gray-200 shadow-md pointer-events-none" style={{ opacity:0 }} />
        </div>
    );
};

export default LineChart;